import { baseAPICall } from '../../services/baseAPICall'
import { clientInstance } from '../../utils/http-client'
import { useQuery } from '@tanstack/react-query'
import { RebateOfferTerms } from '../../models/rebateofferterms'
import { PaginatedResponse } from 'models/paginated-response'
import qs from 'query-string'

export interface RebateOffer {
  id: number
  name: string
  code: string
  state: string
  scope: string
  offer_type: string
  valid_from: string
  valid_to: string
  products: Array<number>
  terms: {
    company_name: string
    unit: string
    tiers: Array<{
      min: number
      max: number | null
      discount: number
      discount_type: string
    }>
    products: Array<{
      product_id: number
      product_name: string
      product_desc: string
      product_variants: Array<{
        variant_id: number
        variant_format: string
        variant_name: string
        variant_desc: string
      }>
    }>
  }
  product_names: string
  total_enrollments: number
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  unpaid_valid_claims: number
  paid_claims: number
  total_validated_units: number | null
  total_validated_payout: number | null
}

export type RebateEnrollment = {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: any
  created: string
}

export type RebateClaimStatus =
  | 'CLAIM_SUBMITTED'
  | 'CLAIM_VALIDATED'
  | 'PENDING_MANUFACTURER_PAYMENT'
  | 'OPERATOR_PAYMENT_SUBMITTED'
  | 'PAID'
  | 'CLAIM_REJECTED'

export type RebateClaim = {
  id: number
  status: RebateClaimStatus
  company_name: string
  company_domain: string | null
  firstbite_id: string | null
  enrollment_date: string
  created: string
  offer_id: number
  offer_name: string
  products: Array<{
    id: number
    name: string
  }>
  rebate_enrollment_id: number
  payout_amount: number | null
  manufacturer_invoice_id: number | null
  notes: string | null
}

export type RebateClaimsResponse = PaginatedResponse<RebateClaim>

export type PaginatedRebateOffers = {
  count: number
  next: string | null
  previous: string | null
  results: RebateOffer[]
}

export type PaginatedRebateEnrollments = {
  count: number
  next: string | null
  previous: string | null
  results: RebateEnrollment[]
}

export type PaginatedRebateClaims = {
  count: number
  next: string | null
  previous: string | null
  results: RebateClaim[]
}

interface GetRebateOffersOptions {
  campaignId?: number
  signal?: AbortSignal
  page?: number
  limit?: number
  [key: string]: any
}

export enum RebateOfferType {
  FIRST_OFFER = 'FIRST_OFFER',
  TIME_OFFER = 'TIME_OFFER',
}

/* -------------
List Rebate Offers
-------------- */
export async function getRebateOffers(params = {}, signal?: AbortSignal) {
  return baseAPICall<PaginatedRebateOffers>(() =>
    clientInstance.get('tradespend/firstbite/offers/', {
      signal,
      params,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export const useGetRebateOffers = (options: GetRebateOffersOptions) =>
  useQuery({
    queryKey: ['rebate-offers', options],
    queryFn: () => getRebateOffers(options),
    refetchOnMount: false,
  })

/* -------------
Get Single Rebate Offer
-------------- */
export async function getRebateOffer(offerId: number) {
  return baseAPICall<RebateOffer>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/`)
  )
}

export const useGetRebateOffer = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-offer', offerId],
    queryFn: () => getRebateOffer(offerId),
    enabled: offerId !== undefined && offerId !== null,
  })

/* -------------
List Rebate Enrollments
-------------- */
export async function getRebateEnrollments(offerId: number) {
  return baseAPICall<PaginatedRebateEnrollments>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/enrollments/`)
  )
}

export const useGetRebateEnrollments = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-enrollments', offerId],
    queryFn: () => getRebateEnrollments(offerId),
    enabled: !!offerId,
  })

/* -------------
List Rebate Claims
-------------- */
export async function getRebateClaims(params = {}, signal?: AbortSignal) {
  const { offerId, ...restParams } = params as {
    offerId?: number
    [key: string]: any
  }
  const endpoint = offerId
    ? `tradespend/firstbite/offers/${offerId}/claims/`
    : 'tradespend/firstbite/claims/'

  return baseAPICall<PaginatedRebateClaims>(() =>
    clientInstance.get(endpoint, {
      signal,
      params: restParams,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

interface GetRebateClaimsOptions {
  offerId?: number
  signal?: AbortSignal
  page?: number
  limit?: number
  [key: string]: any
}

export const useGetRebateClaims = (options: GetRebateClaimsOptions) =>
  useQuery({
    queryKey: ['rebate-claims', options],
    queryFn: () => getRebateClaims(options, options.signal),
    refetchOnMount: false,
  })

/* -------------
Get Rebate Suggestion
-------------- */
type GetRebateSuggestionsBody = {
  campaign_id?: number
  type: string
  basis: string
  min: number
}

export async function getRebateSuggestions(values: GetRebateSuggestionsBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/get-rebate-suggestions/', values)
  )
}

/* -------------
Create Rebate Offer
-------------- */
type CreateRebateOfferBody = {
  name: string
  campaign_id?: number
  valid_from: Date
  valid_to: Date
  terms: string
}

export async function createRebateOffer(values: CreateRebateOfferBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/create-rebate-offer/', values)
  )
}

/* -------------
Get Enrollment Details
-------------- */
interface ProductIdentifier {
  product_identifier: string
  product_identifier_type: string
}

interface ProductInfo {
  num_units: number
  product_identifiers: ProductIdentifier[]
}

interface VariantApprovalInfo {
  [variantId: string]: ProductInfo
}

interface ProductApprovalInfo {
  [productId: string]: VariantApprovalInfo
}

export interface RebateInvoice {
  id: number
  file_url: string | null
  parsed_data: Record<string, any>
  product_approval_info: ProductApprovalInfo | null
  invoice_reference_id: string | null
  created: string
  modified: string
}

export interface RebateClaimDetail {
  id: number
  created: string
  status: string
  payout_amount: number | null
  invoices: RebateInvoice[]
}

export interface RebateOfferProduct {
  product_id: number
  product_name: string
  product_desc: string
  product_variants: Array<{
    variant_id: number
    variant_sku: string
    variant_desc: string
    variant_gtin: string
    variant_name: string
    variant_format: string
  }>
}

export interface RebateEnrollmentDetail {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: {
    unit: string
    tiers: Array<{
      max: number
      min: number
      discount: number
      discount_type: string
    }>
    products: RebateOfferProduct[]
    company_name: string
  }
  created: string
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  paid_claims: number
  unpaid_valid_claims: number
  claims: RebateClaimDetail[]
}

export async function getRebateEnrollmentDetails(enrollmentId: number) {
  return baseAPICall<RebateEnrollmentDetail>(() =>
    clientInstance.get(`tradespend/firstbite/enrollments/${enrollmentId}/`)
  )
}

export const useGetRebateEnrollmentDetails = (enrollmentId: number) =>
  useQuery({
    queryKey: ['rebate-enrollment-details', enrollmentId],
    queryFn: () => getRebateEnrollmentDetails(enrollmentId),
    enabled: !!enrollmentId,
  })

export interface ManufacturerInvoice {
  id: number
  created: string
  amount: number
  status: 'DRAFT' | 'PENDING' | 'PAID' | 'OVERDUE'
  due_date: string
  claim_count: number
  company_name: string
  paid_at?: string
}

interface PaginatedManufacturerInvoices {
  results: ManufacturerInvoice[]
  count: number
}

/* -------------
List Manufacturer Invoices
-------------- */
export async function getManufacturerInvoices() {
  return baseAPICall<PaginatedManufacturerInvoices>(() =>
    clientInstance.get('tradespend/firstbite/invoices/')
  )
}

export function useManufacturerInvoices() {
  const query = useQuery({
    queryKey: ['manufacturer-invoices'],
    queryFn: () => getManufacturerInvoices(),
  })

  return {
    ...query,
  }
}

/* -------------
Get Dashboard Stats
-------------- */
export interface RebateDashboardStats {
  foodservice_chains: number
  claims_received: number
  rebate_value_processed: number
  redemption_rate: number
  claims_by_offer: Array<{
    offer_id: number
    offer_name: string
    count: number
    value: number
  }>
  claims_by_product: Array<{
    product_id: number
    product_name: string
    count: number
    value: number
  }>
  monthly_trends: {
    offers: string[]
    data: Array<{
      month: string
      [key: string]: string | { count: number; value: number }
    }>
  }
}

export async function getRebateDashboardStats(
  startDate?: string,
  endDate?: string
) {
  return baseAPICall<RebateDashboardStats>(() =>
    clientInstance.get('tradespend/firstbite/dashboard-stats/', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
  )
}

export function useGetRebateDashboardStats(
  startDate?: string,
  endDate?: string
) {
  return useQuery({
    queryKey: ['rebate-dashboard-stats', startDate, endDate],
    queryFn: () => getRebateDashboardStats(startDate, endDate),
  })
}

export async function getRebateOfferOptions() {
  return baseAPICall<{ id: number; name: string }[]>(() =>
    clientInstance.get('tradespend/firstbite/offers/options/')
  )
}

export function useGetRebateOfferOptions() {
  return useQuery({
    queryKey: ['rebateOfferOptions'],
    queryFn: getRebateOfferOptions,
  })
}
