import { useQuery } from '@tanstack/react-query'
import { DataTable, useDataTableContext } from 'components/DataTable'
import { IColumnSort } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import {
  CreateContactRequestJobModal,
  MAX_OPPORTUNITIES_REQUESTED,
} from 'components/Modals/CreateContactRequestJobModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import React, { useEffect, useMemo, useState } from 'react'
import {
  BsBriefcase,
  BsDownload,
  BsPhone,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import styled from 'styled-components'
import { featureFlagService } from 'utils/featureFlagService'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { AssignToCampaignModal } from '../../../../features/campaigns/AssignToCampaignModal'
import { useCampaignUnassigner } from '../../../../features/campaigns/useCampaignUnassigner'
import { FilterIdentifier } from '../../../../models/saved_view'
import { getTableQueryKey } from '../../../../utils/getTableQueryKey'
import TableButtonAction from '../../../Buttons/TableButtons/TableButtonAction'
import { CreateDealModalForTable } from '../../../Deals/CreateDealModal/CreateDealModal'

import { TableSearch } from '../../../Filters/TableSearch'
import DistributorAssignModal from '../../../Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from '../../../Modals/AssignmentModal/TagAssignModal'
import { IDropdownItem } from '../../../UI/Dropdown/Dropdown'
import * as S from './CommonTable.styles'

import { ColumnDef } from '@tanstack/react-table'
import { ExportModalProps } from 'components/Modals/ExportModal/ExportModal'
import { CompanyTypeSlugWithFBData } from 'models/companies'
import { PaginatedResponse } from 'models/paginated-response'
import { bulkEditDistributorsParams } from 'services/apiTypes'
import { dataTableSortingStoreRepo } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { cn } from '../../../UI/cn'
import { ManageTagsParams } from 'services/common_table_actions'

const PAGE_SIZE = 100

interface DECommonTableProps<T extends { id: number }> {
  setTotalRowsCount?: (count: number) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifier: FilterIdentifier
  filterIdentifierModifier?: FilterIdentifier
  companyTypeSlug: CompanyTypeSlugWithFBData
  sortableFields?: string[]
  columns: ColumnDef<T, any>[]
  defaultSort?: IColumnSort<object>[]
  searchPlaceholder?: string
  hideCreateCompanyButton?: boolean
  apiGetListAction: (
    params: Record<string, any>
  ) => Promise<PaginatedResponse<T>>
  apiBulkEditTags: (values: ManageTagsParams) => Promise<unknown>
  apiBulkEditDistributors: (
    values: bulkEditDistributorsParams
  ) => Promise<unknown>
  FilterSet: React.FC<{
    filterIdentifier: FilterIdentifier
    hideCampaignsFilter?: boolean
  }>
  ExportModal: React.FC<ExportModalProps>
}

export function DECommonTable<T extends { id: number }>(
  props: DECommonTableProps<T>
) {
  const featureFlag = featureFlagService()

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  const {
    state: { rowSelection, isAllRowsSelected, totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    props.pageParamName
  )

  const hasCampaign = !!props.baseFilters?.campaigns
  const IDENTIFIER =
    props.filterIdentifier + (props.filterIdentifierModifier ?? '')
  const filterStore = filterStoreRepo.getStore(IDENTIFIER as FilterIdentifier)

  const filterParams = {
    ...useFilterParams(filterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const totalRowsSelected = useMemo(() => {
    return isAllRowsSelected
      ? totalSelectedRows
      : Object.keys(rowSelection).length
  }, [isAllRowsSelected, rowSelection])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async () => {
      clearSelectedRows()

      const res = await props.apiGetListAction({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filterAndSortParams,
      })
      props.setTotalRowsCount?.(res.count)
      setTotalRowsInBackend(res.count)
      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner(props.companyTypeSlug, {
    data: data?.results ?? [],
    accessorKey: 'id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaigns,
    setPagination: setPagination,
    totalCount: data?.count ?? 0,
    pageSize: PAGE_SIZE,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const selectedIds = useMemo(() => {
    return (
      data?.results
        ?.filter((_: any, i: number) => rowSelection[i])
        ?.map((r: any) => r.id) ?? []
    )
  }, [data, rowSelection])

  const unselectedIds = useMemo(() => {
    if (!isAllRowsSelected) return []
    return (
      data?.results?.filter((_, i) => !rowSelection[i])?.map((r) => r.id) ?? []
    )
  }, [data, rowSelection, isAllRowsSelected])

  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [openDealModal, setOpenDealModal] = useState(false)
  const [showContactRequest, setShowContactRequest] = useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)

  const handleCreateDeals = () => {
    if (!data) return
    setOpenDealModal(true)
  }

  const columns = props.columns

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  const actions = useMemo(() => {
    const actions: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
      {
        label: 'Export Selected',
        icon: <BsDownload size={20} />,
        callback: () => setShowExportAllModal(true),
      },
    ]

    if (featureFlag.enableContactRequests) {
      actions.push({
        label: 'Request Contacts',
        icon: <BsPhone size={20} />,
        callback: () => setShowContactRequest(true),
        disabled: totalRowsSelected > MAX_OPPORTUNITIES_REQUESTED,
        disabledTooltipText: `To request contacts, select less than ${MAX_OPPORTUNITIES_REQUESTED} opportunities.`,
      })
    }

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        actions.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={props.companyTypeSlug}
              buttonLess
            />
          ),
        })
      } else {
        actions.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign.unassign(),
        })
      }
    }

    return actions
  }, [data, hasCampaign, featureFlag])

  return (
    <TableContainer>
      <CreateContactRequestJobModal
        data={data?.results ?? []}
        open={showContactRequest}
        onClose={() => setShowContactRequest(false)}
        accessorKeys={['id']}
        tableQueryKey={TABLE_QUERY_KEY}
        requestParams={filterAndSortParams}
        companyTypeSlug={props.companyTypeSlug}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={props.apiBulkEditTags}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={props.apiBulkEditDistributors}
      />

      <props.ExportModal
        show={showExportAllModal}
        _handleClose={() => {
          setShowExportAllModal(false)
        }}
        filters={
          isAllRowsSelected
            ? filterAndSortParams
            : {
                ...filterAndSortParams,
                id: selectedIds,
              }
        }
        selectedIds={!isAllRowsSelected ? selectedIds : undefined}
        excludeIds={isAllRowsSelected ? unselectedIds : undefined}
        count={selectedIds.length || data?.count || 0}
      />

      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearch
                filterStore={filterStore}
                searchPlaceholder={
                  props.searchPlaceholder ?? 'Search Opportunities'
                }
                className="flex-1"
              />

              <props.FilterSet
                filterIdentifier={IDENTIFIER as FilterIdentifier}
                hideCampaignsFilter={props.baseFilters?.campaigns}
              />

              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>

            <div className={cn('flex flex-row justify-between gap-4')}>
              <TableButtonAction
                items={actions}
                disabled={!totalSelectedRows}
                selectedRowsCount={totalSelectedRows ?? 0}
              />
              <FilterChips
                identifier={IDENTIFIER as FilterIdentifier}
                clearAllButton
                showActive
                store={filterStore}
              />
            </div>
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching || unassignFromCampaign.isUnassigning}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={props.sortableFields}
        virtualizeRows={true}
        enableRowSelection
        isPaginationEnabled={true}
        defaultSort={props.defaultSort}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            hideCreateCompanyButton={props.hideCreateCompanyButton}
            companyTypeSlug={props.companyTypeSlug}
            onDownloadButtonClick={() => setShowExportAllModal(true)}
          />
        }
      />

      <CreateDealModalForTable
        optimisticUiTarget="none"
        show={openDealModal}
        handleClose={() => setOpenDealModal(false)}
        onDealCreated={refetch}
        queryKey={TABLE_QUERY_KEY}
        filterAndSortParams={filterAndSortParams}
        data={data?.results ?? []}
        idAccessor="id"
        originCampaignId={props.baseFilters?.campaign}
        companyTypeSlug={props.companyTypeSlug}
      />
    </TableContainer>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
