import to from 'await-to-js'
import { CompanyTypeSlug } from 'models/companies'
import { clientInstance } from 'utils/http-client'
import { notifyError } from './toast'
import qs from 'query-string'
import { baseAPICall } from './baseAPICall'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from './queryClient'
import {
  getCampaignTableKey,
  CHAIN_SUMMARY_KEY,
  DEAL_TABLE_KEY,
  CONTACTS_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { Campaign } from 'models/campaign'
import { ChainListResponse } from 'models/chains'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  DOOR_SUMMARY_KEY,
  DOORS_TABLE_KEY,
  HOSPITALS_TABLE_KEY,
  NURSING_HOMES_TABLE_KEY,
  K12_TABLE_KEY,
  UGC_TABLE_KEY,
} from 'constants/tableQueryKeys'
import lodash from 'lodash'

interface CommonTableActionParams {
  include_ids: number[]
  exclude_ids: number[]
  filter_params: Record<string, unknown>
}
/* -------------
Assign To Campaign
-------------- */
// Refer to server/common/table_actions_mixins/BulkAssignToCampaignMixin.py for the server side implementation
function getCampaignAssignEndpoint(companyType: CompanyTypeSlug | undefined) {
  const endpointCompanyTypeMap: Partial<Record<CompanyTypeSlug, string>> = {
    'restaurants-bars': 'restaurants/chains',
    'education-k-12': 'k12/districts',
    'education-cu': 'universities',
    'healthcare-hospitals': 'hospital',
    'healthcare-nursinghomes': 'nursing_home',
    other: 'contacts/contactcompanies',
  }

  return (
    endpointCompanyTypeMap[companyType ?? 'other'] ??
    endpointCompanyTypeMap['other']
  )
}

type AssignToCampaignRequest = {
  campaign_id: number
  campaign_name: string
  campaign_color: string
} & CommonTableActionParams

async function assignToCampaign(
  companyType: CompanyTypeSlug | undefined,
  body: AssignToCampaignRequest
) {
  return baseAPICall(
    () =>
      clientInstance.post(
        `${getCampaignAssignEndpoint(companyType)}/campaigns/assign/`,
        {
          campaign_id: body.campaign_id,
          include_ids: body.include_ids,
          exclude_ids: body.exclude_ids,
          assign: true,
        },
        {
          params: body.filter_params,
          paramsSerializer: (p) => {
            return qs.stringify(p)
          },
        }
      ),
    {
      successMessage: 'Succesfully Assigned To Campaign',
    }
  )
}

export const useAssignToCampaignMutation = (
  oppsListQueryKey: string[],
  chainProxyIdAccessor = 'id',
  companyTypeSlug?: CompanyTypeSlug
) => {
  return useMutation({
    mutationFn: (variables: AssignToCampaignRequest) =>
      assignToCampaign(companyTypeSlug, variables),
    onMutate: async (newCampaign) => {
      if (!oppsListQueryKey) {
        return
      }

      // Base table
      await queryClient.cancelQueries({ queryKey: oppsListQueryKey })
      const prev = queryClient.getQueryData(oppsListQueryKey) as {
        results: any[]
      }
      queryClient.setQueryData(oppsListQueryKey, (old: ChainListResponse) => {
        const data = structuredClone(old)

        newCampaign.include_ids?.forEach((id) => {
          const row = data.results.find(
            (d) => lodash.get(d, chainProxyIdAccessor) === id
          )
          if (row) {
            if (
              !row.campaigns.some(
                (cmp) => cmp.name === newCampaign.campaign_name
              )
            ) {
              row.campaigns = [
                ...row.campaigns,
                {
                  id: newCampaign.campaign_id,
                  name: newCampaign.campaign_name,
                  color: newCampaign.campaign_color,
                } as Campaign,
              ]
            }
          }
        })

        return data
      })

      // Campaign table
      const campaignTableQueryKey = [
        getCampaignTableKey(oppsListQueryKey[0], newCampaign.campaign_id),
      ]
      const prevCamp = queryClient.getQueriesData({
        queryKey: campaignTableQueryKey,
      })
      queryClient.setQueriesData(
        { queryKey: campaignTableQueryKey },
        (old?: { results: any[] }) => {
          if (!old) {
            return
          }
          const data = structuredClone(old)
          newCampaign.include_ids?.forEach((id) => {
            if (
              !data.results.some(
                (it) => lodash.get(it, chainProxyIdAccessor) === id
              )
            ) {
              data.results.push(
                prev?.results.find(
                  (it) => lodash.get(it, chainProxyIdAccessor) === id
                )
              )
            }
          })
          return data
        }
      )

      return { prev, prevCamp: prevCamp?.[0]?.[1] }
    },
    onError: (_, newCampaign, context) => {
      // Restore base table
      queryClient.setQueryData(oppsListQueryKey, context?.prev)

      // Restore campaign table
      queryClient.setQueriesData(
        {
          queryKey: [
            getCampaignTableKey(oppsListQueryKey[0], newCampaign.campaign_id),
          ],
        },
        context?.prevCamp
      )
    },
    onSettled: async (_, __, variables) => {
      // note: the reason we invalidate the query rather than use optimistic ui
      // is because if we remove all rows in the current page, we need to
      // fetch from the server

      // Invalidate the campaign table query
      // using refetch type 'all' because campaigns table is not rendered
      // in the same page as the base table
      void queryClient.invalidateQueries({
        queryKey: [
          getCampaignTableKey(oppsListQueryKey[0], variables.campaign_id),
        ],
        exact: false,
        refetchType: 'all',
      })

      // Invalidate the chain summary (used in select all dropdown)
      void queryClient.invalidateQueries({
        queryKey: [CHAIN_SUMMARY_KEY],
        exact: false,
        refetchType: 'all',
      })

      // Also invalidate deals and contacts tables for the campaign
      void queryClient.invalidateQueries({
        queryKey: [getCampaignTableKey(DEAL_TABLE_KEY, variables.campaign_id)],
        exact: false,
        refetchType: 'all',
      })

      void queryClient.invalidateQueries({
        queryKey: [
          getCampaignTableKey(CONTACTS_TABLE_KEY, variables.campaign_id),
        ],
        exact: false,
        refetchType: 'all',
      })
    },
  })
}

/* -------------
Unassign From Campaign
-------------- */
type UnassignFromCampaignRequest = {
  campaign_id: number
} & CommonTableActionParams

async function unassignFromCampaign(
  companyType: CompanyTypeSlug | undefined,
  body: UnassignFromCampaignRequest
) {
  return baseAPICall(
    () =>
      clientInstance.post(
        `${getCampaignAssignEndpoint(companyType)}/campaigns/assign/`,
        {
          campaign_id: body.campaign_id,
          include_ids: body.include_ids,
          exclude_ids: body.exclude_ids,
          assign: false,
        },
        {
          params: body.filter_params,
          paramsSerializer: (p) => {
            return qs.stringify(p)
          },
        }
      ),
    {
      successMessage: 'Succesfully Unassigned From Campaign',
    }
  )
}

export const useUnassignToCampaignMutation = (
  companyType: CompanyTypeSlug | undefined,
  oppsListQueryKey: string[],
  chainProxyIdAccessor = 'id'
) => {
  return useMutation({
    mutationFn: (variables: UnassignFromCampaignRequest) =>
      unassignFromCampaign(companyType, variables),
    onMutate: async (newCampaign) => {
      if (!oppsListQueryKey) {
        return
      }
      await queryClient.cancelQueries({ queryKey: oppsListQueryKey })

      const prev = queryClient.getQueryData(oppsListQueryKey)

      queryClient.setQueryData(oppsListQueryKey, (old: any) => {
        const data = structuredClone(old)

        if (
          newCampaign.include_ids.length === 0 &&
          newCampaign.exclude_ids.length === 0
        ) {
          data.results = []
          return data
        }

        const results = data.results.filter(
          (it: any) =>
            !newCampaign.include_ids?.includes(
              lodash.get(it, chainProxyIdAccessor)
            )
        )

        data.results = results

        return data
      })

      return { prev }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(oppsListQueryKey, context?.prev)
    },
    onSettled: async (_, __, variables) => {
      // note: the reason we invalidate the query rather than use optimistic ui
      // is because if we remove all rows in the current page, we need to
      // fetch from the server

      // Invalidate the campaign table query
      // in this scenario, the opps list query key is the campaign table query key
      // since we can only unassign from a campaign table
      void queryClient.invalidateQueries({
        queryKey: oppsListQueryKey,
        exact: false,
      })

      // Invalidate the chain summary (used in select all dropdown)
      void queryClient.invalidateQueries({
        queryKey: [CHAIN_SUMMARY_KEY],
        exact: false,
      })

      // Also invalidate deals and contacts tables for the campaign
      void queryClient.invalidateQueries({
        queryKey: [getCampaignTableKey(DEAL_TABLE_KEY, variables.campaign_id)],
        exact: false,
        refetchType: 'all',
      })

      void queryClient.invalidateQueries({
        queryKey: [
          getCampaignTableKey(CONTACTS_TABLE_KEY, variables.campaign_id),
        ],
        exact: false,
        refetchType: 'all',
      })
    },
  })
}

/* -------------
Bulk Create Deals
-------------- */
// Refer to server/common/table_actions_mixins/BulkCreateDealsMixin.py for the server side implementation
export async function commonCreateDeals(
  slug: CompanyTypeSlug,
  options: {
    deals: {
      sales_stage_id?: number | null
      account_owner_id?: number | null
      product_id?: number
      origin_campaign_id?: number | null
      monthly_volume_override?: number | null
      monthly_revenue_override?: number | null
      close_date_override?: string | null
    }[]
  } & CommonTableActionParams
) {
  let companyTypePrefix = 'contacts/contactcompanies'

  switch (slug) {
    case 'restaurants-bars':
      companyTypePrefix = 'restaurants/chains'
      break
    case 'education-k-12':
      companyTypePrefix = 'k12/districts'
      break
    case 'education-cu':
      companyTypePrefix = 'universities'
      break
    case 'healthcare-hospitals':
      companyTypePrefix = 'hospital'
      break
    default:
      companyTypePrefix = 'contacts/contactcompanies'
      break
  }

  const [err, res] = await to(
    clientInstance.post(
      `${companyTypePrefix}/bulk/deals/`,
      {
        include_ids: options.include_ids,
        exclude_ids: options.exclude_ids,
        deals: options.deals,
      },
      {
        params: options.filter_params,
        paramsSerializer: (p) => {
          return qs.stringify(p)
        },
      }
    )
  )

  if (err) {
    notifyError(err.message)
    throw err
  }

  return res
}

/* -------------
Bulk Manage Tags
-------------- */
export type ManageTagsParams = {
  taglist: number[]
  action: 'add' | 'delete'
} & CommonTableActionParams

export type ManageTagsCompanyType =
  | CompanyTypeSlug
  | 'restaurants-bars-doors'
  | 'contacts-table'
  | 'contact-tags'
  | 'deals-table'

export async function commonManageTags({
  values,
  companyType,
}: {
  values: ManageTagsParams
  companyType: ManageTagsCompanyType
}) {
  const urlMap: Partial<Record<ManageTagsCompanyType, string>> = {
    'restaurants-bars': 'restaurants/chains/bulk/tags/',
    'restaurants-bars-doors': 'restaurants/places/bulk/tags/',
    'education-cu': 'universities/bulk/tags/',
    'education-k-12': 'k12/districts/bulk/tags/',
    'healthcare-hospitals': 'hospital/bulk/tags/',
    'healthcare-nursinghomes': 'nursing_home/bulk/tags/',
    'contacts-table': 'contacts/bulk/tags/',
    'contact-tags': 'contacts/bulk/contacttags/',
    'deals-table': 'contacts/deals/bulk/tags/',
  }

  const url = urlMap[companyType] ?? 'contacts/contactcompanies/bulk/tags/'

  const { filter_params, ...rest } = values

  const queryParams = filter_params ?? {}

  // add company_type_slug to the request

  const [err, res] = await to(
    clientInstance.post(
      url,
      { ...rest },
      {
        params: queryParams,
        paramsSerializer: (p) => {
          return qs.stringify(p)
        },
      }
    )
  )

  if (err) {
    notifyError(err.message)
    throw err
  }

  // Invalidate corresponding queries
  const queryKeysMap: Record<string, string[]> = {
    'restaurants-bars': [
      CHAINS_TABLE_KEY,
      DOORS_TABLE_KEY,
      DOOR_SUMMARY_KEY,
      CHAIN_SUMMARY_KEY,
    ],
    'education-cu': [COLLEGE_AND_UNIVERSITY_TABLE_KEY],
    'education-k-12': [K12_TABLE_KEY],
    'healthcare-hospitals': [HOSPITALS_TABLE_KEY],
    'healthcare-nursinghomes': [NURSING_HOMES_TABLE_KEY],

    companies: [UGC_TABLE_KEY],
    companies_from_contacts: [CONTACTS_TABLE_KEY],
    contacts: [CONTACTS_TABLE_KEY],
    deals: [DEAL_TABLE_KEY],
  }

  if (companyType in queryKeysMap) {
    queryKeysMap[companyType].forEach((key) => {
      queryClient.invalidateQueries({ queryKey: [key], exact: false })
    })
  }

  return res
}
