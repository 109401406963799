const SERVICE_MAP = {
  has_delivery: 'Delivery',
  has_dine_in: 'Dine-In',
  has_drive_thru: 'Drive Through',
  has_takeout: 'Takeout',
  has_pickup: 'Pickup',
  has_reservations: 'Reservations',
  has_catering: 'Catering',
  has_counter_service: 'Counter Service',
}

export const SERVICE_OPTIONS = Object.entries(SERVICE_MAP).map(
  ([value, label]) => ({
    value,
    label,
  })
)
