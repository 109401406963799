import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { CONTACTS_COLUMNS } from 'components/Tables/ContactsTable/contactsColumnInfo'
import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'

export default function ContactExportModal(
  props: ExportModalProps & { myContactsOnly: boolean }
) {
  const api = apiService()

  const exportContacts = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportContacts(
      !!props.myContactsOnly,
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Contacts - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const contactColumnHelper = createExportColumnHelper({
    columns: CONTACTS_COLUMNS,
  })

  const columns = [
    {
      title: 'Contact Information',
      elements: [
        contactColumnHelper.retrieveColumn('full_name'),
        contactColumnHelper.retrieveColumn('first_name'),
        contactColumnHelper.retrieveColumn('last_name'),
        contactColumnHelper.retrieveColumn('domain'),
        contactColumnHelper.retrieveColumn('email_preferred'),
        contactColumnHelper.retrieveColumn('email_work'),
        contactColumnHelper.retrieveColumn('email_personal'),
        contactColumnHelper.retrieveColumn('email_generic'),
        contactColumnHelper.retrieveColumn('phone_number'),
        contactColumnHelper.retrieveColumn('url'),
        contactColumnHelper.retrieveColumn('company_chain_instagram_handle'),
        contactColumnHelper.retrieveColumn('linkedin_url'),
        contactColumnHelper.retrieveColumn('contact_company'),
        contactColumnHelper.retrieveColumn('title'),
        contactColumnHelper.retrieveColumn('seniority'),
        contactColumnHelper.retrieveColumn('department'),
        contactColumnHelper.retrieveColumn('contact_labels'),
      ],
    },
    {
      title: 'Activity',
      elements: [
        contactColumnHelper.retrieveColumn('created'),
        contactColumnHelper.retrieveColumn('modified'),
        contactColumnHelper.retrieveColumn('notes'),
      ],
    },
    {
      title: 'Account Details',
      elements: [
        contactColumnHelper.retrieveColumn('sales_stages'),
        contactColumnHelper.retrieveColumn('account_owners'),
        contactColumnHelper.retrieveColumn('company_tags'),
        contactColumnHelper.retrieveColumn('deal_count'),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Contacts Download"
      subTitle="Download up to 10,000 contacts at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Download Contacts"
      columns={columns}
      exportAPIAction={exportContacts}
      {...props}
    />
  )
}
