import apiService from 'services/api'
import { Tag } from 'models/tags'
import { toast } from 'react-toastify'
import { AssignModal, AssignModalProps } from './BaseAssignModal'
import { useDataTableContext } from 'components/DataTable'
import { formatInteger } from 'utils/formatting'
import dayjs from 'dayjs'
import { TaglistCell } from '../../FbUI/StagePill'
import { ManageTagsParams } from 'services/common_table_actions'

type TagAssignModalProps = {
  tableObjAccessor?: string
  tagParent?: 'products' | 'contacts'
  apiTagsAction: (params: ManageTagsParams) => Promise<any>
} & Omit<
  AssignModalProps<Tag, any>,
  | 'apiAction'
  | 'title'
  | 'subtitle'
  | 'pluralLabel'
  | 'singularLabel'
  | 'onConfirmAddNew'
  | 'items'
  | 'tableObjAccessor'
>

export default function TagAssignModal({
  tableObjAccessor = 'taglist',
  tagParent = 'products',
  apiTagsAction,
  ...props
}: TagAssignModalProps) {
  const api = apiService()
  const { data: tags, refetch } = api.useGetTags(tagParent)
  const {
    state: { totalRowsInBackend },
  } = useDataTableContext()

  const createNewTag = async (value: string) => {
    if (value.length > 50) {
      toast.error('Tag length limited to 50 characters.')
      return null
    }

    return await api.postTag(
      {
        tag: value,
      },
      tagParent
    )
  }

  if (!tags) return null

  const title = `Assign ${tagParent === 'products' ? 'Tags' : 'Contact Labels'}`
  const subtitle = (
    <span>
      Select {tagParent === 'products' ? 'tags' : 'contact labels'} to apply or
      remove from <b>{formatInteger(totalRowsInBackend)}</b> selected row
      {totalRowsInBackend > 1 ? 's' : ''}.
    </span>
  )
  const pluralLabel = tagParent === 'products' ? 'Tags' : 'Contact Labels'
  const singularLabel = tagParent === 'products' ? 'Tag' : 'Contact Label'

  return (
    <AssignModal
      {...props}
      title={title}
      subtitle={subtitle}
      pluralLabel={pluralLabel}
      singularLabel={singularLabel}
      onConfirmAddNew={createNewTag}
      tableObjAccessor={tableObjAccessor!}
      apiAction={apiTagsAction}
      items={tags}
      refetchFn={refetch}
      columns={[
        {
          accessorKey: 'tag',
          header: 'Name',
          cell: (info) => {
            return <TaglistCell taglist={[info.row.original]} />
          },
        },
        {
          accessorKey: 'created',
          header: 'Date Created',
          cell: (info) => dayjs(info.getValue()).format('MMM DD YYYY'),
          size: 100,
        },
        {
          accessorKey: 'last_applied',
          header: 'Last Applied',
          cell: (info) => dayjs(info.getValue()).format('MMM DD YYYY'),
          size: 100,
        },
        {
          accessorKey: tagParent === 'products' ? 'opp_count' : 'contact_count',
          header:
            tagParent === 'products' ? 'Unique Companies' : 'Unique Contacts',
          meta: { rightAlign: true },
          size: 100,
        },
      ]}
      initialOrdering={[{ id: 'last_applied', desc: true }]}
      tableFooter={(selectedRows) => {
        return (
          <TaglistCell
            nullPlaceholder={<></>}
            taglist={selectedRows}
            maxBadges={Infinity}
            className="items-center justify-center pt-3"
          />
        )
      }}
    />
  )
}
